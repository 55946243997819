import React, { useEffect, useMemo } from "react"
import LandingPageLayout from "../layouts/landing-page-layout/LandingPageLayout";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from 'gatsby-background-image'
import { Button, Col } from 'react-bootstrap';
import { GatsbyImage, gatsbyImageData } from 'gatsby-plugin-image';
import { handleNavigate } from "../services/Navigate";

import './404.scss';

const NotFoundPage = () => {
    const data = useStaticQuery(
    graphql`
        query template404Assets {
            backgroundImage404: imageSharp(resize: {originalName: { eq: "background-gradient.jpg" }}) {
                fluid(quality: 90, maxWidth: 2560) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }

            ufo404: file(relativePath: { eq: "hero/ufo.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: FULL_WIDTH
                        placeholder: BLURRED
                        formats: [WEBP, AVIF]
                    )
                }
            }
        }
        `
    );

    useEffect(() => {
        handleNavigate('/404');
    }, []);

    const backgroundImage = data.backgroundImage404.fluid;
    const ufoData = data.ufo404.childImageSharp.gatsbyImageData;

    const heroDom = useMemo(() => {
        return <BackgroundImage Tag='section' fluid={backgroundImage} className='landingPageHeroBackground404 pt-0 pb-0'>
            <GatsbyImage image={ufoData} className={`ufoBackground smaller`} style={{mixBlendMode: 'multiply'}} alt='ufo'/>
            <GatsbyImage image={ufoData} className={`ufoBackground smaller lowerOpacity`} style={{mixBlendMode: 'darken'}} alt='ufo2'/>

            <Col xs={12} md={6} lg={5} xl={4} className='heroText text-center mx-auto my-auto'>
                <small>404 | Page not found</small>
                <h1>Oops! You've got lost!</h1>
                <p className='mt-4 mb-4'>It seems like the page you are looking for doesn’t exist on this website. Please check the URL and try again later. Meanwhile, try to use the links from our menu or from the footer.</p>
                <Button onClick={() => handleNavigate('/')}>Take me home</Button>
            </Col>
        </BackgroundImage>
    }, [backgroundImage, ufoData]);

    return <LandingPageLayout is404Page={true} classNames='template404' heroBackground={heroDom} includeLandingPageDOM={true} onlyLogo={false} pageTitle='404'>
        
    </LandingPageLayout>
}

export default NotFoundPage

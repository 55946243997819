import * as React from "react"
import LandingPage from './landing-page/LandingPage';
import NotFoundPage from './404';
import AccountPage from './account-page/AccountPage';
import LoginPage from './login/Login';
import ForgotPassword from './forgot-password/ForgotPassword';
import VerifyEmail from "./verify-email/VerifyEmail";
import { Router } from '@reach/router';

import './index.scss';

const IndexPage = () => {
    return (
        <Router>
            <LandingPage path='/'/>
            <LoginPage path='/login'/>
            <ForgotPassword path='/reset-password'/>
            <AccountPage path='/account'/>
            <VerifyEmail path='/verify-email'/>
            <NotFoundPage default/>
        </Router>
    )
};

export default IndexPage;